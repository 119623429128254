
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Login from '@/mixins/login';
import { API_HOST, COUNTERPART_LIST, COUNTERPART_MAP, map2list, NORAML_PAGE_SIZE } from '@/store/constants';
import { IRequestMethod, request } from '@mobro/request';
import { miniNameOptionMap, miniTypeOptionsMap } from './constants';
import { Iobject } from '@mobro/libs/lib/type';
import { copySelectTextContents, MoDate } from '@mobro/libs';
import CreateWxacodeMultiDialog from './CreateWxacodeMultiDialog.vue';
import PreviewPrintTemplateDialog from './PreviewPrintTemplateDialog.vue';
import MultiDownloadWxacodeDialog from './MultiDownloadWxacodeDialog.vue';
import axios from 'axios';

@Component({
    components: { CreateWxacodeMultiDialog, PreviewPrintTemplateDialog, MultiDownloadWxacodeDialog },
})
export default class App extends mixins(Login) {
    counterpartOptionList = COUNTERPART_LIST;
    private searchForm = {
        isPrint: '0',
        isUsed: '0',
        mpt: 'release',
        mpn: 'ynnc',
        isSystemCreate: '1',
        userId: '',
        keyWord: '',
        carLicenseIdList: '',
        printTime: '',
    };
    wxacodeImageList: Iobject[] = [];
    showCreateWxacodeMultiDialog = false;
    showPreviewPrintTemplateDialog = false;
    showMultiDownloadDialog = false;
    extraForm = {
        count: 0,
        isPrint: '',
        printId: '',
    };
    multipleSelection: Iobject[] = [];

    async onUpdatePrintStatus(records: Iobject[]) {
        if (records.length < 1) {
            this.$message.error('至少选择一条记录');
            return;
        }

        await this.$confirm(`确认你的页码是否够？？`);
        await this.$confirm(`请逐一确认条件是否正确`);
        await this.$confirm(`当前打印状态：${this.searchForm.isPrint}`);
        await this.$confirm(`打印时间先于：${this.searchForm.printTime}`);
        await this.$confirm(`挪车码是否正在使用：${this.searchForm.isUsed}`);
        await this.$confirm(`小程序版本：${this.searchForm.mpt}`);
        await this.$confirm(`关键词：${this.searchForm.keyWord}`);
        await this.$confirm(`打印状态要被修改成 ${this.extraForm.isPrint} 。没得问题吧？？？？？？？`);

        if (!this.searchForm.keyWord) {
            this.$message.warning('关键词为空，是否符合预期');
        }

        if (!this.searchForm.printTime) {
            this.$message.error('【打印时间先于】为空，不可修改');
            return;
        }
        if (this.extraForm.isPrint === this.searchForm.isPrint) {
            this.$message.error('前后打印状态的值一致，不可修改');
            return;
        }

        request({
            url: '/api/common/wxacode/updateisprint',
            method: IRequestMethod.POST,
            params: {
                wxacodeIds: records.map(s => s.wxacodeId).join(','),
                isPrint: this.extraForm.isPrint,
            },
        });
    }

    handleSelectionChange(val: Iobject[]) {
        this.multipleSelection = val;
    }
    async onMultiDownloadWxacodeClick() {
        if (this.extraForm.count <= 0) return;
        await this.$confirm(`将要下载${this.extraForm.count}张${this.searchForm.mpt}版本的挪车码，确定要批量下载吗？`);

        axios
            .post(
                `/api/common/wxacode/download?printId=${this.extraForm.printId}`,
                {
                    ...this.searchForm,
                    pageSize: Number(this.extraForm.count),
                },
                {
                    baseURL: API_HOST,
                    withCredentials: true,
                    responseType: 'blob',
                },
            )
            .then(res => {
                return res.data;
            })
            .then(blob => {
                // 创建一个下载链接
                const downloadLink = document.createElement('a');

                // 设置下载链接的属性
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `挪车码_${this.searchForm.mpn}_${this.searchForm.mpt}.zip`;

                // 将下载链接添加到页面
                document.body.appendChild(downloadLink);

                // 模拟点击下载链接
                downloadLink.click();

                this.extraForm = {
                    ...this.extraForm,
                    count: 0,
                    printId: '',
                };

                // 从页面中移除下载链接
                document.body.removeChild(downloadLink);
            });
    }

    async onProseCopyClick(text: string) {
        await copySelectTextContents(text);
        this.$message.success('复制成功！');
    }
    tableRowClassName(x: Iobject) {
        const { rowIndex } = x;
        if (rowIndex === 1) {
            return 'warning-row';
        } else if (rowIndex === 3) {
            return 'success-row';
        }
        return '';
    }
    get miniTypeOptionsList() {
        return map2list(miniTypeOptionsMap);
    }
    get miniNameOptionList() {
        return map2list(miniNameOptionMap);
    }

    /**
     * =============
     */

    private pagination: Iobject = {
        current: 0,
        pageSize: NORAML_PAGE_SIZE[2],
        pageSizes: NORAML_PAGE_SIZE,
        total: 0,
    };

    /** 防抖不好写，算求了踏马的 */
    onSearchByButton() {
        this.pagination.current = 1;

        this.getWxaImageList();
    }

    getWxaImageList() {
        request({
            url: '/api/common/wxacode/list',
            method: IRequestMethod.POST,
            params: {
                ...this.searchForm,
                current: this.pagination.current,
                pageSize: this.pagination.pageSize,
            },
        }).then(res => {
            this.wxacodeImageList = (res.data || []).map((wxacode: Iobject) => {
                return {
                    ...wxacode,
                    isSystemCreateLabel: COUNTERPART_MAP[wxacode.isSystemCreate as keyof typeof COUNTERPART_MAP],
                    mptLabel: miniTypeOptionsMap[wxacode.envVersion as keyof typeof miniTypeOptionsMap],
                    mpnLabel: miniNameOptionMap[wxacode.mpn as keyof typeof miniNameOptionMap],
                    isPrintLabel: COUNTERPART_MAP[wxacode.isPrint as keyof typeof COUNTERPART_MAP],
                    isUsedLabel: COUNTERPART_MAP[wxacode.isUsed as keyof typeof COUNTERPART_MAP],
                    updateTimeLable: MoDate.format('YYYY-MM-DD hh:mm:ss', wxacode.updateTime),
                };
            });
            this.pagination.total = res.total;
            this.pagination.current = res.current;
        });
    }

    handleSizeChange(size: number) {
        this.pagination.pageSize = size;
        this.pagination.current = 1;
        this.getWxaImageList();
    }

    onPageCurrentChange(x: number) {
        this.pagination.current = x;
        this.getWxaImageList();
    }
}
