
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class MultiDownloadWxacodeDialog extends Vue {
    // ========================================
    // ========== modal base template =========
    @Prop() value!: boolean;
    @Prop({ default: '弹窗' }) title!: string;

    visible = false;

    @Watch('value', { immediate: true })
    handleWatchValue(val: boolean) {
        this.visible = val;
    }

    @Watch('visible', { immediate: true })
    handleWatchVisible(val: boolean) {
        this.$emit('input', this.visible);
    }
}
